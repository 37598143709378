<template>
  <div class="remote-open-gate">
    <div class="remote-open-gate-main">
      <park-header></park-header>
      <div class="remote-open-gate-content">
        <!--<page-title :title="'远程开闸'"></page-title>-->
        <go-back-title :title="'远程开闸'"></go-back-title>
        <!--<van-tabs v-model:active="active" @change="onTabChange" color="#999999">-->
          <div title="远程开闸">
            <van-pull-refresh
              v-model="isLoading"
              success-text="刷新成功"
              @refresh="onRefreshGate"
            >
              <!--<van-list-->
                <!--v-model:loading="isLoading"-->
                <!--:finished="gatePageOption.total <= gatePageOption.list.length"-->
                <!--finished-text="没有更多了"-->
                <!--@load="onLoadGate">-->
                <div class="gate-item"  v-for="item in gatePageOption.list">
                  <div class="channel-path-name">{{(item.info && item.info.name) || '未获取名称'}}{{item.info.inOut | filterInOut}}</div>
                  <div class="channel-path-button-wrap">
                    <van-button type="info" class="channel-path-button"
                                @click="doRemoteOpen(item)">开闸</van-button>
                    <van-button disabled  class="channel-path-button">关闸</van-button>
                  </div>
                </div>
              <!--</van-list>-->
            </van-pull-refresh>
          </div>
          <!--<van-tab title="开闸记录">-->
            <!--<van-pull-refresh-->
              <!--v-model="isLoading"-->
              <!--success-text="刷新成功"-->
              <!--@refresh="onRefreshRecord"-->
            <!--&gt;-->
              <!--<van-list-->
                <!--v-model:loading="isLoading"-->
                <!--:finished="recordPageOption.total <= recordPageOption.list.length"-->
                <!--finished-text="没有更多了"-->
                <!--@load="onLoadRecord"-->
              <!--&gt;-->
                <!--<div class="record-item"-->
                     <!--:key="item.id"-->
                     <!--v-for="item in recordPageOption.list">-->
                  <!--<div class="record-item-left">-->
                    <!--<div class="record-item-left-top">{{formateDate(item.create_time)}}</div>-->
                    <!--<div class="record-item-left-bottom">{{item.channel_path}}</div>-->
                  <!--</div>-->
                  <!--<div class="record-item-right">-->
                    <!--<div class="success" v-if="item.status==2">开闸成功</div>-->
                    <!--<div class="fail" v-else>开闸失败</div>-->
                  <!--</div>-->
                <!--</div>-->
              <!--</van-list>-->
            <!--</van-pull-refresh>-->
          <!--</van-tab>-->
        <!--</van-tabs>-->
      </div>
    </div>
  </div>
</template>

<script>
import parkHeader from '../../public/park-header'
import goBackTitle from '../../public/go-back-title'
// import pageTitle from '../../public/page-title'
import * as common from '../../api/common'
import * as api from '../../api/api'
import * as axios from 'axios'
import * as mqtt from '../../api/wsconnect.js'
import { mapState } from 'vuex'
export default {
  name: 'remote-open-gate',
  components: {
    parkHeader,
    // pageTitle
    goBackTitle
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    })
  },
  data () {
    return {
      plate_number: '',
      active: '远程开闸',
      isLoading: false,
      gatePageOption: {
        list: [
          // {
          //   info: {
          //     name: "通道1",
          //     channel_id: 1
          //   }
          // },
          // {
          //   info: {
          //     name: "通道2",
          //     channel_id: 2
          //   }
          // }
        ],
        total: 0,
        page_no: 1,
        page_size: common.globlePagesize,
      },
      recordPageOption: {
        list: [],
        total: 0,
        page_no: 1,
        page_size: common.globlePagesize,
      }
    }
  },
  created () {
    this.plate_number = this.randomP()
  },
  methods: {
    // onTabChange (e) {
    //   console.log(e)
    //   if (e == 0) {
    //     if (!this.gatePageOption.total) {
    //       this.gatePageOption.page_no = 0
    //       // this.onLoadGate ()
    //     }
    //   } else if (e == 1) {
    //     if (!this.recordPageOption.total) {
    //       this.recordPageOption.page_no = 0
    //       this.onLoadRecord ()
    //     }
    //   }
    // },
    onLoadGate () {
      this.gatePageOption.page_no++
      this.initList().catch(e => console.log(e))
    },
    onRefreshGate () {
      this.gatePageOption.page_no = 1
      this.gatePageOption.total = 0
      this.gatePageOption.list = []
      this.initList().catch(e => console.log(e))
    },
    // onRefreshRecord () {
    //   this.recordPageOption.page_no = 1
    //   this.recordPageOption.list = []
    //   this.getRecordList()
    // },
    // onLoadRecord () {
    //   this.recordPageOption.page_no++
    //   this.getRecordList()
    // },
    randomP () {
      var z='2345678abcdefghijmnprtABCDEFGHIJMNPQRT'
      var s = 'N'
      for (var k = 0;k < 7;k++){
        var m = z[Math.floor(Math.random() * z.length)];
        s += m;
      }
      return s;
    },
    async doRemoteOpen(row) {
      try {
        let para = {
          plate_number: this.plate_number,
          sid: localStorage.getItem('sid'),
          channel_id: row.info.channel_id || row.id,
          ...api.getPublicPara()
        }
        // if (!para.access_token) {
        //   this.$router.push({
        //     path: '/login',
        //     query: {
        //       ...api.getQuery.apply(this)
        //     }
        //   })
        //   return
        // }
        this.$toast.loading('正在请求中...')
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}`;
        await mqtt.subscribe(topic)
        this.isLoading = true
        mqtt.Client.config[mqtt.topics.mq_remote_open_gate] = this.getRemoteOpenResult

        this.isLoading = false
        let res = await api.axiosRequest(api.urlList.fenglin.open_gate.remote_open_gate, para)
        // vh_inout_out_open_gate
        if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
        this.$toast.clear()
      } catch (e) {
        common.showErrorMessage(this, e)
      }
    },
    getRemoteOpenResult (message) {
      console.log('remote-open-gate-msg', message)
      if (message) {
        if (message.code == common.resCode.OK) {
          this.$toast.success('远程开闸成功')
        } else {
          this.$toast.fail('远程开闸失败')
        }
      }
    },
    // getChannelPathlist() {
    //   let data = {
    //     page_no: this.gatePageOption.page_no,
    //     page_size: this.gatePageOption.page_size,
    //     type: 51,
    //     customer_id: localStorage.getItem('customer_id'),
    //     ...api.getPublicPara()
    //   }
    //   axios({
    //     method: 'post',
    //     url: common._baseUrl + '/api/' + api.urlList.park.get_device,
    //     data: data
    //   }).then(res => {
    //     console.log(res)
    //     let data = res.data
    //     if (data && data.code == common.resCode.OK) {
    //       // this.gatePageOption.list = data.list
    //       this.gatePageOption.list = this.gatePageOption.list.concat(data.list)
    //       this.gatePageOption.total = data.total
    //     } else {
    //       this.gatePageOption.list = []
    //       this.gatePageOption.total = 0
    //     }
    //     this.isLoading = false
    //   })
    //     .catch(e => {
    //       console.log(e)
    //       this.isLoading = false
    //     })
    // },
    async getList (msg) {
      console.log(msg)
      if (msg.code == common.resCode.OK) {
        this.gatePageOption.list = this.gatePageOption.list.concat(msg.list)
        this.gatePageOption.total = msg.total || 0
      }
    },
    async initList() {
      try {
        this.$toast.loading('正在请求中...')
        let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_device_list}`;
        await mqtt.subscribe(topic)
        this.isLoading = true
        mqtt.Client.config[mqtt.topics.mq_query_device_list] = this.getList
        let para = {
          sid: localStorage.getItem('sid'),
          page_no: this.gatePageOption.page_no,
          page_size: this.gatePageOption.page_size
        }
        if ([
          common.userTypesTxt.groupCompany,
          common.userTypesTxt.park,
        ].includes(this.userInfo.userTypeName) === false) {
          this.$toast('请使用管理员或集团账号登录')
          return
        }

        let res = await api.axiosApiRequests(api.apiUrlList.park.get_device, para)
        this.$toast.clear()
        this.isLoading = false
        if (res.code != common.resCode.OK) return this.$toast(res.msg || '请求出错')
      } catch (e) {
        common.showErrorMessage(this, e)
      }
    },
    getRecordList () {
      let data = {
        page_no: this.recordPageOption.page_no,
        page_size: this.recordPageOption.page_size,
        customer_id: localStorage.getItem("customer_id"),
        ...api.getPublicPara('v1')
      }


      axios({
        method: 'post',
        url: common._baseUrl + '/api/' + api.urlList.remote.get_list,
        data: data
      })
        .then(res => {
          console.log(res)
          let data = res.data
          if (data && data.code == common.resCode.OK) {
            this.recordPageOption.total = data.total
            this.recordPageOption.list = this.recordPageOption.list.concat(data.list)
          } else {
            this.recordPageOption.total = 0
            this.recordPageOption.list = []
          }
          this.isLoading = false
        })
        .catch(e => {
          console.log(e)
          this.isLoading = false
        })
    },
    formateDate(datetime) {
      if (!datetime) return ''
      // let  = "2019-11-06T16:00:00.000Z"
      function addDateZero(num) {
        return (num < 10 ? "0" + num : num);
      }

      let d = new Date(datetime);
      console.log(d)
      let formatdatetime = d.getFullYear() + '-' + addDateZero(d.getMonth() + 1) + '-' + addDateZero(d.getDate()) + ' ' + addDateZero(d.getHours()) + ':' + addDateZero(d.getMinutes());
      return formatdatetime;
    },
  },
  filters: {
    filterInOut (inout) {
      if (inout === 1) {
        return '(进口)'
      } else if (inout === 2) {
        return '(出口)'
      } else {
        return ''
      }
    }
  },
  mounted () {
    // new mqtt.Client()
    // this.onTabChange (0)
    // this.getChannelPathlist ()
    this.initList().catch(e => console.log(e))
  },
  beforeDestroy () {
    let topic = `${mqtt.topicPrefix}${localStorage.getItem('sid')}/${mqtt.topics.mq_query_device_list}`
    mqtt.unsubscribe(topic).catch(e => console.log(e))
    delete mqtt.Client.config[mqtt.topics.mq_query_device_list]
  }
}
</script>

<style scoped lang="scss">
@import "../../theme/index";
.remote-open-gate {
  display: flex;
  @extend .w-100-h-100;
  /*<!--background-color: $global-theme-bg-blue;;-->*/
  &-main {
    position: relative;
    @extend .content-wrap-gap;
    width: 100%;
    .remote-open-gate-content {
      box-sizing: border-box;
      margin: 1rem 1rem 0 1rem;
      .gate-item{
        display: flex;
        padding: 0 1rem;
        flex-direction: column;
        justify-content: center;
        height: 12.2rem;
        background: #405688;
        border-radius: 4px;
        /*opacity: 0.13;*/
        margin: .4rem;
        .channel-path-name{
          text-align: left;
          width: 100%;
          text-indent: 4%;
          font-size: $global-font-size-large;
          color: white;
          /*background: white;*/
        }
        .channel-path-button-wrap{
          width: 100%;
          display: flex;
          margin-top: 2rem;
          justify-content: center;
          .channel-path-button{
            margin: 0 3%;
            width: 43%;
            height: 4rem;
            line-height: 4rem;
            /*background: linear-gradient(180deg, #FFFFFF 0%, #D1D1D1 100%);*/
            /*box-shadow: 0px 1px 2px 0px rgba(67, 67, 67, 0.5);*/
            /*border-radius: .4rem;*/
          }
        }
      }

      .record-item {
        display: flex;
        width: 100%;
        height: 8.4rem;
        background: #FFFFFF;
        border-bottom: .1rem solid $global-theme-gray;
        .record-item-left{
          box-sizing: border-box;
          display: flex;
          padding: .4rem 0;
          flex-direction: column;
          justify-content: space-around;
          align-items: flex-start;
          /*background: gray;*/
          .record-item-left-top{
            font-size: $global-font-size-normal;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #333333;
            /*line-height: 22px;*/
            /*background: red;*/
          }
          .record-item-left-bottom{
            text-align: left;
            /*background: blue;*/
          }
          .record-item-left-right {
            font-size: $global-font-size-small;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #535353;
           }
        }
        .record-item-right {
          display: flex;
          align-items: center;
          margin-left: auto;
          .success {
            font-size: $global-font-size-normal;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #33B200;
          }
          .fail{
            font-size: $global-font-size-normal;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: $global-theme-gray;
          }
        }
      }

      & .gate-item:first-child, & .record-item:first-child {
        margin-top: 1rem;
      }
    }
  }
  /deep/ .van-tab__text{
    font-size: $global-font-size-normal;
  }
  /deep/ .van-tab--active .van-tab__text{
    color: $global-theme-blue !important;
  }
  /deep/ .van-tabs__line{
    background-color: $global-theme-blue !important;
  }
}
</style>
